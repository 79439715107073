<template>
  <section class="container">
    <stockTopMenu
      curPosition="销售记录"
      windowId="salesRecord"
      module="money"
    ></stockTopMenu>
    <section
      class="row"
      v-loading="infoDetailLoading"
      element-loading-text="请稍后，全力加载中..."
      element-loading-spinner="el-icon-loading"
    >
      <asideContent
        ref="ording"
        invoicesType="2"
        @sendObj="getCurOrdingObj"
      ></asideContent>
      <section class="section-main" v-if="ordingId">
        <section style="background: #fff; padding: 20px 20px 10px">
          <tabsMenu
            v-if="tabList.length"
            :list="tabList"
            @curItem="getCurTab"
            :curTab="curTab"
          ></tabsMenu>
        </section>
        <section
          class="invoice-detail"
          id="goods-scroll"
          @scroll="scrollChange"
        >
          <!-- 原货品信息 -->
          <section id="tabcont0" class="invoice-card card-bottom">
            <div class="goods-top row align-center space-between">
              <div
                v-if="showList.afterSalesType !== '0'"
                class="row align-center"
              >
                <span class="title-label"></span>
                <p>
                  <span
                    style="
                      background: #f8475f;
                      color: #fff;
                      padding: 1px 4px;
                      border-radius: 4px;
                      font-size: 14px;
                      margin-right: 5px;
                    "
                    >售后</span
                  >
                  <span class="title-text">货品信息</span>
                  <span style="color: #999">（售后做单的货品信息）</span>
                  <span
                    v-if="ordingData"
                    style="color: #000; margin-left: 20px"
                  >
                    总件数：{{ initCount }}件 &nbsp;&nbsp;&nbsp; 总金额：￥{{
                      ordingData ? ordingData.totalPrice : ""
                    }}
                  </span>
                </p>
              </div>
              <div v-else class="row align-center">
                <span class="title-label"></span>
                <p>
                  <span
                    style="
                      background: #067cf2;
                      color: #fff;
                      padding: 1px 4px;
                      border-radius: 4px;
                      font-size: 14px;
                      margin-right: 5px;
                    "
                    >原始</span
                  >
                  <span class="title-text">货品信息</span>
                  <span style="color: #999"
                    >（未发生售后之前，本单的货品信息）</span
                  >
                  <span v-if="ordingData" style="color: #000"
                    >总件数：{{ initCount }}件</span
                  >
                </p>
              </div>
              <div class="show-hidden" @click="unfoldHandel('foldOne')">
                <span v-if="unfoldObj.foldOne"
                  >收起<i class="el-icon-arrow-up"></i
                ></span>
                <span v-else>展开<i class="el-icon-arrow-down"></i></span>
              </div>
            </div>
            <div v-if="unfoldObj.foldOne">
              <grid-manager
                v-if="goodsTable.length"
                :option="gridOption"
              ></grid-manager>
            </div>
          </section>
          <!-- 最新货品信息 -->
          <section
            v-if="newGoodsInfos.length"
            id="tabcont1"
            class="invoice-card card-bottom"
          >
            <div class="goods-top row align-center space-between">
              <div class="row align-center">
                <span class="title-label"></span>
                <p>
                  <span
                    style="
                      background: #f8475f;
                      color: #fff;
                      padding: 1px 4px;
                      border-radius: 4px;
                      font-size: 14px;
                      margin-right: 5px;
                    "
                    >最新</span
                  >
                  <span class="title-text">货品信息</span>
                  <span style="color: #999"
                    >（已产生售后，本单的货品信息）</span
                  >
                  <span v-if="currentCount" style="color: #000"
                    >总件数：{{ currentCount }}件</span
                  >
                </p>
              </div>
              <div class="show-hidden" @click="unfoldHandel('foldTwo')">
                <span v-if="unfoldObj.foldTwo"
                  >收起<i class="el-icon-arrow-up"></i
                ></span>
                <span v-else>展开<i class="el-icon-arrow-down"></i></span>
              </div>
            </div>
            <div v-if="unfoldObj.foldTwo">
              <grid-manager
                v-if="newGoodsInfos.length"
                :option="gridOption1"
              ></grid-manager>
            </div>
          </section>
          <!-- 单据信息 -->
          <section
            v-if="showList.hasInvoicesInfo === 'Y'"
            id="tabcont2"
            class="invoice-card"
          >
            <div class="goods-top top-bottom row align-center space-between">
              <div class="row align-center">
                <span class="title-label"></span>
                <span class="title-text">单据信息</span>
              </div>
              <div class="show-hidden" @click="unfoldHandel('foldThr')">
                <span v-if="unfoldObj.foldThr"
                  >收起<i class="el-icon-arrow-up"></i
                ></span>
                <span v-else>展开<i class="el-icon-arrow-down"></i></span>
              </div>
            </div>
            <div
              class="row flex-wrap invoice-data"
              v-if="ordingData && unfoldObj.foldThr"
            >
              <div>
                本单金额：<span class="red-text"
                  >￥{{ ordingData.totalPrice }}</span
                >
              </div>
              <div>
                零售金额：<span class="red-text"
                  >￥{{ ordingData.totalSellPrice }}</span
                >
              </div>
              <div>
                已收金额：<span class="red-text"
                  >￥{{ ordingData.receivedAmount }}</span
                >
              </div>
              <div v-if="ordingData.discountAmount">
                活动金额：<span class="red-text"
                  >￥{{ ordingData.discountAmount }}</span
                >
              </div>
              <div v-if="ordingData.changePrice">
                变动金额：<span class="red-text"
                  >￥{{ ordingData.changePrice }}</span
                >
              </div>
              <div>
                销售状态：
                <span v-if="ordingData.state === '-1'">已取消</span>
                <span v-if="ordingData.state === '1'">待支付</span>
                <span v-if="ordingData.state === '2'">支付中</span>
                <span v-if="ordingData.state === '3'">已支付</span>
                <span v-if="ordingData.state === '4'">待审批</span>
                <span v-if="ordingData.state === '5'">已作废</span>
              </div>
              <div>
                店铺名称：<span>{{ ordingData.deptName }}</span>
              </div>
              <div v-if="ordingData.giftDetail">
                赠品信息：<span>{{ ordingData.giftDetail }}</span>
              </div>
              <div>
                单据来源：<span>{{
                  ordingData.source === "1" ? "手机端" : "客户端"
                }}</span>
              </div>
              <div>
                订单性质：<span>{{ ordingData.orderTypeTitle }}</span>
              </div>
              <div>
                销售单号：<span>{{ ordingData.orderNo }}</span>
              </div>
              <div v-if="ordingData.originalInnerSn">
                原销售单号：<span>{{ ordingData.originalInnerSn }}</span>
              </div>
              <div>
                外部单号：<span>{{ ordingData.outerSn }}</span>
              </div>
              <div>
                业绩方式：
                <span>{{
                  ordingData.brokerage === "1"
                    ? "个人业绩"
                    : ordingData.brokerage === "2"
                    ? "班组业绩"
                    : ordingData.brokerage === "3"
                    ? "分销"
                    : ""
                }}</span>
              </div>
              <div
                v-if="
                  ordingData.brokerage === '1' && ordingData.shoppingGuideName
                "
              >
                营业员：<span>{{ ordingData.shoppingGuideName }}</span>
              </div>
              <div>
                创建时间：<span>{{ ordingData.createTime }}</span>
              </div>
              <div
                v-if="
                  ordingData.brokerage === '2' && ordingData.shoppingGuideName
                "
                class="row"
                style="line-height: 20px; margin-top: 5px; width: 100%"
              >
                <span style="width: 77px; color: #999">班组人员：</span>
                <span class="description">{{
                  ordingData.shoppingGuideName
                }}</span>
              </div>
              <div v-if="ordingData.createTerminalTitle">
                创建订单收银台：<span>{{
                  ordingData.createTerminalTitle
                }}</span>
              </div>
              <div v-if="ordingData.endTerminalTitle">
                结束订单收银台：<span>{{ ordingData.endTerminalTitle }}</span>
              </div>
            </div>
          </section>
          <!-- 促销信息 -->
          <section
            v-if="showList.hasPromotionInfo === 'Y' || thirdCouponInfos[0]"
            id="tabcont3"
            class="invoice-card"
          >
            <div
              class="goods-top row align-center space-between"
              :class="[!unfoldObj.foldFou ? 'top-bottom' : '']"
            >
              <div class="row align-center">
                <span class="title-label"></span>
                <span class="title-text">促销/优惠信息</span>
              </div>
              <div class="show-hidden" @click="unfoldHandel('foldFou')">
                <span v-if="unfoldObj.foldFou"
                  >收起<i class="el-icon-arrow-up"></i
                ></span>
                <span v-else>展开<i class="el-icon-arrow-down"></i></span>
              </div>
            </div>
            <div v-show="unfoldObj.foldFou">
              <template v-if="promotionInfo.length">
              <el-table
                border
                :data="promotionInfo"
                :header-cell-style="{ background: '#FAFAFA', color: '#436481' }"
                :cell-style="{ padding: '10px 0' }"
              >
                <el-table-column prop="name" label="促销名称"></el-table-column>
                <el-table-column prop="type" label="促销类型"></el-table-column>
                <el-table-column
                  prop="activityNo"
                  label="促销编号"
                ></el-table-column>
                <el-table-column prop="discountAmount" label="减扣金额">
                  <template slot-scope="scope">
                    <div style="color: red">
                      {{ scope.row.discountAmount }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="paymentState" label="促销单详情">
                  <template slot-scope="scope">
                    <span
                      style="color: #0b83f3; cursor: pointer"
                      @click="showDetail(scope.row)"
                      >查看详情</span
                    >
                  </template>
                </el-table-column>
              </el-table>
              </template>
              <template v-if="thirdCouponInfos.length">
              <el-table
                border
                size="mini"
                style="margin-top: 10px;"
                :data="thirdCouponInfos"
                :header-cell-style="{ background: '#FAFAFA', color: '#436481' }"
                :cell-style="{ padding: '10px 0' }">
                <el-table-column prop="title" label="优惠券名称"></el-table-column>
                <el-table-column prop="source" label="优惠券来源"></el-table-column>
              </el-table>
              </template>
            </div>
          </section>
          <!-- 会员信息 -->
          <section
            v-if="showList.hasMemberInfo === 'Y'"
            id="tabcont4"
            class="invoice-card"
          >
            <div class="goods-top top-bottom row align-center space-between">
              <div class="row align-center">
                <span class="title-label"></span>
                <span class="title-text">会员信息</span>
              </div>
              <div class="show-hidden" @click="unfoldHandel('foldFiv')">
                <span v-if="unfoldObj.foldFiv"
                  >收起<i class="el-icon-arrow-up"></i
                ></span>
                <span v-else>展开<i class="el-icon-arrow-down"></i></span>
              </div>
            </div>
            <div
              v-if="vipData && unfoldObj.foldFiv"
              class="row flex-wrap invoice-data"
            >
              <div>
                会员昵称：<span>{{ vipData.nickname }}</span>
              </div>
              <div>
                会员卡号：<span>{{ vipData.cardNumber }}</span>
              </div>
              <div>
                累计消费金额：<span>{{ vipData.totalSellAmount }}</span>
              </div>
              <div>
                件单价：<span>{{ vipData.unitPrice }}</span>
              </div>
              <div>
                开卡时间：<span>{{ vipData.createTime }}</span>
              </div>
              <div>
                累计消费次数：<span>{{ vipData.totalSellCount }}</span>
              </div>
              <div>
                会员积分：<span>{{ vipData.integralBalance }}</span>
              </div>
              <div>
                手机号：<span>{{ vipData.phone }}</span>
              </div>
              <div>
                开卡店铺：<span>{{ vipData.deptName }}</span>
              </div>
              <div>
                平均消费金额：<span>{{ vipData.avgSellAmount }}</span>
              </div>
              <div>
                会员余额：<span>{{ vipData.accountBalance }}</span>
              </div>
            </div>
          </section>
          <!-- 收款信息 -->
          <section
            v-if="showList.hasPaymentInfo === 'Y'"
            id="tabcont5"
            class="invoice-card"
          >
            <div
              class="goods-top row align-center space-between"
              :class="[!unfoldObj.foldSix ? 'top-bottom' : '']"
            >
              <div class="row align-center">
                <span class="title-label"></span>
                <span class="title-text">收款信息</span>
              </div>
              <div class="show-hidden" @click="unfoldHandel('foldSix')">
                <span v-if="unfoldObj.foldSix"
                  >收起<i class="el-icon-arrow-up"></i
                ></span>
                <span v-else>展开<i class="el-icon-arrow-down"></i></span>
              </div>
            </div>
            <div v-if="payList && payList.length > 0 && unfoldObj.foldSix">
              <el-table
                border
                :data="payList"
                :header-cell-style="{ background: '#FAFAFA', color: '#436481' }"
                :cell-style="{ padding: '10px 0' }"
              >
                <el-table-column
                  prop="paymentName"
                  label="收款方式"
                ></el-table-column>
                <el-table-column
                  prop="paymentPrice"
                  label="收款金额"
                ></el-table-column>
                <el-table-column prop="paymentState" label="支付状态">
                  <template slot-scope="scope">
                    <el-tag
                      size="small"
                      effect="dark"
                      :type="
                        scope.row.paymentState == 0
                          ? 'primary'
                          : scope.row.paymentState == 1
                          ? 'success'
                          : scope.row.paymentState == 2
                          ? 'info'
                          : scope.row.paymentState == 3
                          ? 'danger'
                          : ''
                      "
                    >
                      {{
                        scope.row.paymentState == 0
                          ? "已创建"
                          : scope.row.paymentState == 1
                          ? "已支付"
                          : scope.row.paymentState == 2
                          ? "已取消"
                          : scope.row.paymentState == 3
                          ? "支付中"
                          : ""
                      }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createTime"
                  label="创建时间"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="updateTime"
                  label="更新时间"
                ></el-table-column>
                <el-table-column
                  prop="outerNo"
                  label="支付凭证号"
                ></el-table-column>
                <el-table-column
                  prop="serialNumber"
                  label="流水号"
                ></el-table-column>
              </el-table>
            </div>
          </section>
          <!-- 小票预览 -->
          <section
            v-if="showList.hasReceiptInfo === 'Y' && hasReceiptInfo === 'Y'"
            id="tabcont6"
            class="invoice-card"
          >
            <div class="goods-top top-bottom row align-center space-between">
              <div class="row align-center">
                <span class="title-label"></span>
                <span class="title-text">小票预览</span>
              </div>
              <div class="show-hidden" @click="unfoldHandel('foldSev')">
                <span v-if="unfoldObj.foldSev"
                  >收起<i class="el-icon-arrow-up"></i
                ></span>
                <span v-else>展开<i class="el-icon-arrow-down"></i></span>
              </div>
            </div>
            <div class="receipts-img" v-if="unfoldObj.foldSev">
              <webview
                v-if="webViewUrlPreview"
                class="webview"
                :src="webViewUrlPreview"
              ></webview>
            </div>
          </section>
          <!-- 审批信息 -->
          <section
            v-if="showList.hasReviewInfo === 'Y'"
            id="tabcont7"
            class="invoice-card"
          >
            <div
              class="goods-top row align-center space-between"
              :class="[!unfoldObj.foldEit ? 'top-bottom' : '']"
            >
              <div class="row align-center">
                <span class="title-label"></span>
                <span class="title-text">审批信息</span>
              </div>
              <div class="show-hidden" @click="unfoldHandel('foldEit')">
                <span v-if="unfoldObj.foldEit"
                  >收起<i class="el-icon-arrow-up"></i
                ></span>
                <span v-else>展开<i class="el-icon-arrow-down"></i></span>
              </div>
            </div>
            <div v-show="unfoldObj.foldEit">
              <el-table
                border
                :data="reviewInfos"
                :header-cell-style="{ background: '#FAFAFA', color: '#436481' }"
                :cell-style="{ padding: '10px 0' }"
              >
                <el-table-column
                  prop="formSn"
                  label="审批编号"
                  width="220"
                ></el-table-column>
                <el-table-column
                  prop="flowType"
                  label="流程类型"
                  width="200"
                ></el-table-column>
                <el-table-column prop="state" label="状态">
                  <template slot-scope="scope">
                    <el-tag
                      size="mini"
                      effect="dark"
                      :type="
                        scope.row.state === '-2' || scope.row.state === '4'
                          ? 'info'
                          : scope.row.state === '2'
                          ? 'success'
                          : scope.row.state === '3'
                          ? 'danger'
                          : ''
                      "
                    >
                      {{
                        scope.row.state === "-2" || scope.row.state === "4"
                          ? "已撤回"
                          : scope.row.state === "2"
                          ? "已通过"
                          : scope.row.state === "3"
                          ? "已拒绝"
                          : "待审核"
                      }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="submitName"
                  label="发起人"
                ></el-table-column>
                <el-table-column
                  prop="submitTime"
                  label="提交时间"
                ></el-table-column>
                <el-table-column
                  prop="lastUpdateTime"
                  label="更新时间"
                ></el-table-column>
                <el-table-column
                  prop="userName"
                  label="审批人"
                ></el-table-column>
                <el-table-column
                  prop="refuseDesc"
                  label="拒绝原因"
                  width="130"
                ></el-table-column>
                <el-table-column
                  prop="paymentState"
                  label="审批详情"
                  width="150"
                >
                  <template slot-scope="operation">
                    <el-button
                      type="text"
                      @click="
                        () => {
                          reviewInfo = operation.row;
                          AuditDetailsModal = true;
                        }
                      "
                    >
                      查看详情
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </section>
          <!-- 售后信息 -->
          <section
            v-if="showList.hasAftersalesInfo === 'Y'"
            id="tabcont8"
            class="invoice-card"
          >
            <div
              class="goods-top row align-center space-between"
              :class="[!unfoldObj.foldNan ? 'top-bottom' : '']"
            >
              <div class="row align-center">
                <span class="title-label"></span>
                <span class="title-text">售后信息</span>
              </div>
              <div class="show-hidden" @click="unfoldHandel('foldNan')">
                <span v-if="unfoldObj.foldNan"
                  >收起<i class="el-icon-arrow-up"></i
                ></span>
                <span v-else>展开<i class="el-icon-arrow-down"></i></span>
              </div>
            </div>
            <div v-show="unfoldObj.foldNan">
              <el-table
                border
                :data="aftersalesInfo"
                :header-cell-style="{ background: '#FAFAFA', color: '#436481' }"
                :cell-style="{ padding: '10px 0' }"
              >
                <el-table-column
                  prop="salesTypeName"
                  label="售后类型"
                  width="140"
                ></el-table-column>
                <el-table-column
                  prop="afterInnerSn"
                  label="售后单号"
                  width="220"
                ></el-table-column>
                <el-table-column
                  prop="createTime"
                  label="售后时间"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="realPrice"
                  label="货品金额"
                  width="120"
                ></el-table-column>
                <el-table-column prop="realPrice" label="状态" width="120">
                  <template slot-scope="scope">
                    <el-tag
                      effect="dark"
                      type="primary"
                      v-if="scope.row.effectState === '0'"
                      >待生效</el-tag
                    >
                    <el-tag
                      effect="dark"
                      type="success"
                      v-if="scope.row.effectState === '1'"
                      >已生效</el-tag
                    >
                    <el-tag
                      effect="dark"
                      type="danger"
                      v-if="scope.row.effectState === '2'"
                      >已失效</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注"></el-table-column>
              </el-table>
            </div>
          </section>
          <!-- 物流信息 -->
          <section
            v-if="showList.logisticsInfo === 'Y'"
            id="tabcont9"
            class="invoice-card"
          >
            <div
              class="goods-top row align-center space-between"
              :class="[!unfoldObj.foldTwe ? 'top-bottom' : '']"
            >
              <div class="row align-center">
                <span class="title-label"></span>
                <span class="title-text">物流信息</span>
              </div>
              <div class="show-hidden" @click="unfoldHandel('foldTwe')">
                <span v-if="unfoldObj.foldTwe"
                  >收起<i class="el-icon-arrow-up"></i
                ></span>
                <span v-else>展开<i class="el-icon-arrow-down"></i></span>
              </div>
            </div>
            <div v-show="unfoldObj.foldTwe">
              <el-table
                border
                :data="logisticsInfo"
                :header-cell-style="{ background: '#FAFAFA', color: '#436481' }"
                :cell-style="{ padding: '10px 0' }"
              >
                <el-table-column
                  prop="recipientName"
                  label="收件人"
                  width="140"
                ></el-table-column>
                <el-table-column
                  prop="recipientPhone"
                  label="手机号"
                  width="220"
                ></el-table-column>
                <el-table-column
                  prop="recipientAddress"
                  label="收货地址"
                ></el-table-column>
                <el-table-column prop="orderType" label="下单方式">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.orderType === "1"
                        ? "京东自动下单"
                        : scope.row.orderType === "2"
                        ? "手动下单"
                        : ""
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="state" label="物流状态" align="center">
                  <template slot-scope="scope">
                    <el-tag
                      effect="dark"
                      :type="
                        scope.row.state == -1
                          ? 'warning'
                          : scope.row.state == 0
                          ? 'primary'
                          : scope.row.state == 2
                          ? 'success'
                          : 'primary'
                      "
                    >
                      {{
                        scope.row.state == -1
                          ? "已取消"
                          : scope.row.state == 0
                          ? "已打包"
                          : scope.row.state == 2
                          ? "已签收"
                          : "运输中"
                      }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="logisticsName"
                  label="快递公司"
                ></el-table-column>
                <el-table-column
                  prop="expressNo"
                  label="快递单号"
                ></el-table-column>
                <el-table-column label="操作" width="150">
                  <template slot-scope="scope">
                    <el-button
                      v-if="scope.row.type.toString() === '1'"
                      type="text"
                      size="small"
                      @click="cancelLogistics(scope.row)"
                      >取消</el-button
                    >
                    <el-button
                      v-if="scope.row.orderType === '2'"
                      type="text"
                      size="small"
                      @click="sendExpressage(scope.row)"
                      >修改</el-button
                    >
                    <el-button
                      type="text"
                      size="small"
                      @click="showTrack(scope.row)"
                      >物流轨迹</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </section>
          <!-- 客订信息 -->
          <section
            v-if="showList.hasBookingInfo === 'Y'"
            id="tabcont10"
            class="invoice-card"
          >
            <div
              class="goods-top row align-center space-between"
              :class="[!unfoldObj.foldThir ? 'top-bottom' : '']"
            >
              <div class="row align-center">
                <span class="title-label"></span>
                <span class="title-text">客订信息</span>
              </div>
              <div class="show-hidden" @click="unfoldHandel('foldThir')">
                <span v-if="unfoldObj.foldThir"
                  >收起<i class="el-icon-arrow-up"></i
                ></span>
                <span v-else>展开<i class="el-icon-arrow-down"></i></span>
              </div>
            </div>
            <div v-show="unfoldObj.foldThir">
              <el-table
                border
                :data="bookingApplyList"
                :header-cell-style="{ background: '#FAFAFA', color: '#436481' }"
                :cell-style="{ padding: '10px 0' }"
              >
                <el-table-column
                  prop="guestBookingOrderNo"
                  label="客订单号"
                  width="220"
                ></el-table-column>
                <el-table-column
                  prop="applyType"
                  label="客订类型"
                  width="200"
                ></el-table-column>
                <el-table-column
                  prop="stateDesc"
                  label="状态"
                ></el-table-column>
                <el-table-column
                  prop="unitName"
                  label="发货店铺"
                ></el-table-column>
                <el-table-column prop="paymentState" label="操作" width="150">
                  <template slot-scope="operation">
                    <el-button
                      type="text"
                      v-if="
                        operation.row.state == '0' || operation.row.state == '1'
                      "
                      @click="closeSuited(operation.row)"
                    >
                      关闭
                    </el-button>
                    <el-button
                      type="text"
                      style="color: red"
                      @click="outOfStockOrderDatil(operation.row)"
                    >
                      查看详情
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </section>
        </section>
        <section class="bot-fixed row align-center" style="padding-left: 20px">
          <div
            v-if="
              handeBtnList.indexOf('3') !== -1 &&
              ordingData &&
              hasReceiptInfo === 'Y'
            "
            class="tools-btn"
            @click="btnClick('3')"
            style="background: #9079c1"
          >
            打印小票
          </div>
<!--          <div-->
<!--            v-if="handeBtnList.indexOf('16') !== -1"-->
<!--            class="tools-btn"-->
<!--            @click="btnClick('16')"-->
<!--            style="background: #9079c1"-->
<!--          >-->
<!--            修改订单-->
<!--          </div>-->
          <div
            v-if="handeBtnList.indexOf('4') !== -1"
            class="tools-btn"
            @click="btnClick('4')"
            style="background: #dd8595"
          >
            继续支付
          </div>
          <div
            v-if="handeBtnList.indexOf('7') !== -1"
            class="tools-btn"
            @click="btnClick('7')"
            style="background: #43435c"
          >
            取消订单
          </div>
          <div
            v-if="btnAction.isRetreat === 'Y'"
            class="tools-btn"
            style="background: #f00"
            @click="exchangeHandel('1')"
          >
            退货退款
          </div>
          <div
            v-if="btnAction.isAllRetreat === 'Y'"
            class="tools-btn"
            style="background: #f00"
            @click="exchangeHandel('4')"
          >
            整单退
          </div>
          <!-- <div
            v-if="btnAction.isActivityChange === 'Y'"
            class="tools-btn"
            style="background: #ff9000"
            @click="exchangeHandel('2')">
            促销换货
          </div>
          <div
            v-if="btnAction.isSizeOrColorChange === 'Y'"
            class="tools-btn"
            style="background: #ff9000"
            @click="exchangeHandel('3')">
            色码换货
          </div> -->
          <div
            v-if="ordingData && ordingData.state == '6'"
            class="tools-btn"
            style="background: #f00"
            @click="exchangeHandel('return')"
          >
            确认退货
          </div>
          <div
            v-if="ordingData && ordingData.state == '6'"
            class="tools-btn"
            style="background: #43435c"
            @click="exchangeHandel('noReturn')"
          >
            取消退货
          </div>
          <div
            v-if="
              handeBtnList.indexOf('17') !== -1 &&
              showList.afterSalesType === '0'
            "
            class="tools-btn"
            @click="sendExpressage()"
          >
            寄件到客
          </div>
          <div
            v-if="btnAction.isLackGoodsBooking == 'Y'"
            class="tools-btn"
            @click="outOfStockOrder()"
          >
            缺货客订
          </div>
        </section>
      </section>
      <section class="section-main" style="background: #fff" v-else>
        <noDataState textDesc="暂无订单详情数据" marginTop="280"></noDataState>
      </section>
    </section>
    <DoReceipts
      v-if="DoReceiptsModal"
      :webViewUrl="webViewUrl"
      @close="DoReceiptsModal = false"
    ></DoReceipts>
    <PromotionDetails
      v-if="promotionModal"
      :promotionObj="promotionObj"
      @close="promotionModal = false"
    >
    </PromotionDetails>
    <PayDialog
      v-if="payTypeDialog"
      :vipData="vipData"
      :orderMoney="orderMoney"
      :againPay="againPay"
      @close="closePayModule"
    ></PayDialog>
    <CancelOrder
      v-if="CancelOrderModal"
      @close="cancelClose"
      :receivedAmount="ordingData.receivedAmount"
      :ordingId="ordingId"
    ></CancelOrder>
    <AuditDetails
      v-if="AuditDetailsModal"
      :reviewInfo="reviewInfo"
      @close="AuditDetailsModal = false"
    ></AuditDetails>
    <ReturnGoods
      v-if="returnVisible"
      :invoicesItem="invoicesItem"
      @close="returnVisible = false"
      @refresh="refreshHandel(1)"
    ></ReturnGoods>
    <outOfStockOrder
      v-if="outOfStockVisible"
      :invoicesItem="invoicesItem"
      @close="outOfStockVisible = false"
      @refresh="refreshHandel(4)"
    ></outOfStockOrder>
    <ExchangeGoods
      v-if="exchangeVisible"
      :invoicesItem="invoicesItem"
      @close="exchangeVisible = false"
      @refresh="refreshHandel(2)"
    ></ExchangeGoods>
    <AllOrdingReturn
      v-if="allReturnVisible"
      :invoicesItem="invoicesItem"
      @close="allReturnVisible = false"
      @refresh="refreshHandel(3)"
    ></AllOrdingReturn>
    <SendGoods
      v-if="sendVisible"
      :snData="snData"
      @close="sendVisible = false"
      @refresh="queryInvoices"
    ></SendGoods>
    <TrackDialog
      v-if="showTrackDialog"
      :expressNo="trackData"
      @close="showTrackDialog = false"
    ></TrackDialog>
  </section>
</template>

<script>
import { ipcApiRoute, specialIpcRoute } from "@/api/main";
import { ipc } from "@/utils/ipcRenderer";
import stockTopMenu from "../../components/stockTopMenu.vue";
import tabsMenu from "../../components/tabsMenu.vue";
import ExchangeGoods from "./components/ExchangeGoods.vue";
import ReturnGoods from "./components/ReturnGoods.vue";
import asideContent from "./components/asideContent.vue";
import noDataState from "@/components/noDataState.vue";
import DoReceipts from "./components/DoReceipts.vue";
import PromotionDetails from "./components/PromotionDetails.vue";
import AuditDetails from "./components/AuditDetails.vue";
import CancelOrder from "./components/CancelOrder.vue";
import AllOrdingReturn from "./components/AllOrdingReturn";
import SendGoods from "./components/SendGoods";
import TrackDialog from "@/views/expressDelivery/components/trackDialog";
import PayDialog from "@/components/PayDialog.vue";
import request from "@/libs/http/index";
import outOfStockOrder from "./components/outOfStockOrder.vue";
import { erpPackageRecallJdExpress } from "@/libs/http/api";
import {
  visitorCancelApplay,
  queryBookingApplyPojo,
} from "@/libs/http/modules/customer";
import {
  queryInvoicesInfo,
  generatePayInfo,
  takeOutPendingList,
  cancelOrdingState,
  queryInvoiceNewInfo,
  confirmedReturnOrder, posOrderPayInfo,
} from "@/libs/http/modules/posOrder";
export default {
  components: {
    stockTopMenu,
    tabsMenu,
    asideContent,
    ExchangeGoods,
    ReturnGoods,
    noDataState,
    DoReceipts,
    PromotionDetails,
    PayDialog,
    AuditDetails,
    CancelOrder,
    SendGoods,
    TrackDialog,
    AllOrdingReturn,
    outOfStockOrder,
  },
  data() {
    return {
      hasReceiptInfo: "",
      infoDetailLoading: true,
      curTab: "",
      tabList: [],
      domArray: [],
      DoReceiptsModal: false,
      promotionModal: false,
      AuditDetailsModal: false,
      ordingId: "",
      invoicesNo: "",
      ordingData: null,
      vipData: null,
      payList: [],
      goodsTable: [],
      promotionInfo: [],
      thirdCouponInfos: [],
      sell: require("@/assets/images/sell.png"),
      give: require("@/assets/images/give.png"),
      back: require("@/assets/images/return.png"),
      rite: require("@/assets/images/rite.png"),
      ned: require("@/assets/images/ned.png"),
      gridOption: {
        gridManagerName: "old-goods-list",
        firstLoading: true,
        height: "auto",
        isCombSorting: true,
        supportAutoOrder: false,
        supportCheckbox: false,
        supportConfig: true,
        disableAutoLoading: true,
        supportMenu: false,
        disableBorder: true,
        disableLine: true,
        columnData: [
          {
            key: "goodsNature",
            text: "类型",
            align: "center",
            width: "70px",
            template: () => {
              return `
								<div>
								  <img style="width:40px;height:40px;" v-if="row.goodsNature === '1'" :src="sell" />
                  <img style="width:40px;height:40px;" v-if="row.goodsNature === '2'" :src="give" />
                  <img style="width:40px;height:40px;" v-if="row.goodsNature === '4'" :src="back" />
                  <img style="width:40px;height:40px;" v-if="row.goodsNature === '3'" :src="rite" />
                  <img style="width:40px;height:40px;" v-if="row.goodsNature === '6'" :src="ned" />
								</div>
							`;
            },
          },
          {
            key: "goodsName",
            text: "货品信息",
            align: "center",
            width: "320px",
            template: () => {
              return `
								<div class="row align-center" style="text-align:left;">
									<div style="position:relative;overflow:hidden;width:102px;height:92px;border-radius:4px;">
										<el-image v-if="row.goodsImg && row.goodsImg[0]" style="width:102px;height:92px;border-radius:4px;float:left;" :preview-src-list="row.goodsImg" :src="row.goodsImg[0]"></el-image>
										<div v-else style="width:102px;height:92px;border-radius:4px;text-align:center;line-height:92px;color:#999;background:#f5f5f5;">暂无图片</div>
										<div v-if="row.promoteActivityId || row.goodsNature === '2' || row.isAdd === 1"
											style="width:70px;height:22px;line-height:22px;position:absolute;top:4px;right:-19px;transform: rotate(45deg);text-align:center;color:#fff;"
											:style="row.isAdd === 1 ? 'background: linear-gradient(0deg, rgb(116,47,169), rgb(101,41,147), rgb(72,30,105))' : row.goodsNature === '2' ? 'background: linear-gradient(0deg, #208AEC, #02D7F0, #00C6F7)' : 'background: linear-gradient(-90deg, #EC4620, #F08702, #F7D400)'">
											<span>{{row.isAdd === 1 ? '加购' : row.goodsNature === '2' ? '赠品' : '促销'}}</span>
										</div>
									</div>
									<div class="column space-between" style="margin-left:10px;flex:1;width:170px;height: 90px;">
										<div style="font-size:18px;color:#333;">{{row.goodsName}}</div>
										<div style="font-size:14px;color:#999;">货号：{{row.goodsNo}}</div>
										<div style="font-size:14px;color:#999;">条码：{{row.barCode}}</div>
									</div>
								</div>
							`;
            },
          },
          {
            key: "colorDesc",
            text: "颜色 / 尺码",
            align: "center",
            width: "130px",
            template: () => {
              return `
								<div v-if="row.attrList" class="row space-center align-center text-center" style="border:1px solid #DEEEF9;background:#fff;color:#333;line-height:26px;border-radius:2px;cursor:pointer;">
									<p v-for="(item, index) in row.attrList.slice(0,2)" :key="item.code" style="">{{item.value}}
										<span v-if="index == 0" style="margin:0 3px;">,</span>
									</p>
								</div>
                			`;
            },
          },
          {
            key: "number",
            text: "数量",
            align: "center",
            width: "100px",
            template: () => {
              return `
								<div style="font-size:20px;">
									{{row.number}}
								</div>
                			`;
            },
          },
          {
            key: "tagPrice",
            text: "吊牌价",
            align: "center",
            template: () => {
              return `
								<div class="row align-center space-center">
									<span style="font-size:20px;">￥{{row.tagPrice}}</span>
								</div>
                			`;
            },
          },
          {
            key: "sellPrice",
            text: "零售价",
            align: "center",
            template: () => {
              return `
								<div class="row align-center space-center">
									<span style="font-size:20px;">￥{{row.sellPrice}}</span>
								</div>
            				`;
            },
          },
          {
            key: "unitRealPrice",
            text: "实卖价(单价)",
            align: "center",
            template: () => {
              return `
								<div class="row align-center space-center">
									<span style="color:#666;font-size:20px;">￥{{row.unitRealPrice}}</span>
								</div>
                			`;
            },
          },
          {
            key: "totalRealPrice",
            text: "金额(元)",
            align: "center",
            template: () => {
              return `
								<div class="row align-center space-center" style="color:#F8475F;font-size:20px;">
									￥{{row.totalRealPrice}}
								</div>
                			`;
            },
          },
        ],
        ajaxData: this.showGoodsTable,
      },
      gridOption1: {
        gridManagerName: "new-goods-list",
        firstLoading: true,
        height: "auto",
        isCombSorting: true,
        supportAutoOrder: false,
        supportCheckbox: false,
        supportConfig: true,
        disableAutoLoading: true,
        supportMenu: false,
        disableBorder: true,
        disableLine: true,
        columnData: [
          {
            key: "goodsNature",
            text: "类型",
            align: "center",
            width: "70px",
            template: () => {
              return `
								<div>
								  <img style="width:40px;height:40px;" v-if="row.goodsNature === '1'" :src="sell" />
                  <img style="width:40px;height:40px;" v-if="row.goodsNature === '2'" :src="give" />
                  <img style="width:40px;height:40px;" v-if="row.goodsNature === '4'" :src="back" />
                  <img style="width:40px;height:40px;" v-if="row.goodsNature === '3'" :src="rite" />
                  <img style="width:40px;height:40px;" v-if="row.goodsNature === '6'" :src="ned" />
								</div>
							`;
            },
          },
          {
            key: "goodsName",
            text: "货品信息",
            align: "center",
            width: "320px",
            template: () => {
              return `
								<div class="row align-center" style="text-align:left;">
									<div style="position:relative;overflow:hidden;width:102px;height:92px;border-radius:4px;">
										<el-image v-if="row.goodsImg && row.goodsImg[0]" style="width:102px;height:92px;border-radius:4px;float:left;" :preview-src-list="row.goodsImg" :src="row.goodsImg[0]"></el-image>
										<div v-else style="width:102px;height:92px;border-radius:4px;text-align:center;line-height:92px;color:#999;background:#f5f5f5;">暂无图片</div>
										<div v-if="row.promoteActivityId || row.goodsNature === '2' || row.isAdd === 1"
											style="width:70px;height:22px;line-height:22px;position:absolute;top:4px;right:-19px;transform: rotate(45deg);text-align:center;color:#fff;"
											:style="row.isAdd === 1 ? 'background: linear-gradient(0deg, rgb(116,47,169), rgb(101,41,147), rgb(72,30,105))' : row.goodsNature === '2' ? 'background: linear-gradient(0deg, #208AEC, #02D7F0, #00C6F7)' : 'background: linear-gradient(-90deg, #EC4620, #F08702, #F7D400)'">
											<span>{{row.isAdd === 1 ? '加购' : row.goodsNature === '2' ? '赠品' : '促销'}}</span>
										</div>
									</div>
									<div class="column space-between" style="margin-left:10px;flex:1;width:170px;height: 90px;">
										<div style="font-size:18px;color:#333;">{{row.goodsName}}</div>
										<div style="font-size:14px;color:#999;">货号：{{row.goodsNo}}</div>
										<div style="font-size:14px;color:#999;">条码：{{row.barCode}}</div>
									</div>
								</div>
							`;
            },
          },
          {
            key: "colorDesc",
            text: "颜色 / 尺码",
            align: "center",
            width: "130px",
            template: () => {
              return `
								<div v-if="row.attrList" class="row space-center align-center text-center" style="border:1px solid #DEEEF9;background:#fff;color:#333;line-height:26px;border-radius:2px;cursor:pointer;">
									<p v-for="(item, index) in row.attrList.slice(0,2)" :key="item.code" style="">{{item.value}}
										<span v-if="index == 0" style="margin:0 3px;">,</span>
									</p>
								</div>
                			`;
            },
          },
          {
            key: "number",
            text: "数量",
            align: "center",
            width: "100px",
            template: () => {
              return `
								<div style="font-size:20px;">
									{{row.number}}
								</div>
                			`;
            },
          },
          {
            key: "tagPrice",
            text: "吊牌价",
            align: "center",
            template: () => {
              return `
								<div class="row align-center space-center">
									<span style="font-size:20px;">￥{{row.tagPrice}}</span>
								</div>
                			`;
            },
          },
          {
            key: "sellPrice",
            text: "零售价",
            align: "center",
            template: () => {
              return `
								<div class="row align-center space-center">
									<span style="font-size:20px;">￥{{row.sellPrice}}</span>
								</div>
            				`;
            },
          },
          {
            key: "unitRealPrice",
            text: "实卖价(单价)",
            align: "center",
            template: () => {
              return `
								<div class="row align-center space-center">
									<span style="color:#666;font-size:20px;">￥{{row.unitRealPrice}}</span>
								</div>
                			`;
            },
          },
          {
            key: "totalRealPrice",
            text: "金额(元)",
            align: "center",
            template: () => {
              return `
								<div class="row align-center space-center" style="color:#F8475F;font-size:20px;">
									￥{{row.totalRealPrice}}
								</div>
                			`;
            },
          },
        ],
        ajaxData: this.showGoodsTables,
      },
      handeBtnList: [],
      showList: null,
      scrollFlag: true,
      aftersalesInfo: [],
      payTypeDialog: false,
      totalPrice: "",
      againPay: null,
      orderMoney: null,
      promotionObj: null,
      reviewInfos: [],
      reviewInfo: null,
      hasReviewing: "N",
      CancelOrderModal: false,
      newGoodsInfos: [],
      bookingApplyList: [],
      currentCount: 0,
      initCount: 0,
      unfoldObj: {
        foldOne: true,
        foldTwo: true,
        foldThr: true,
        foldFou: true,
        foldFiv: true,
        foldSix: true,
        foldSev: true,
        foldEit: true,
        foldNan: true,
        foldTen: true,
        foldEve: true,
        foldTwe: true,
        foldThir: true,
      },
      webViewUrl: "",
      webViewUrlPreview: "",
      returnVisible: false,
      exchangeVisible: false,
      invoicesItem: null,
      btnAction: {},
      allReturnVisible: false,
      crmAmount: "",
      otherAmount: "",
      showTrackDialog: false,
      trackData: {},
      logisticsInfo: [],
      sendVisible: false,
      outOfStockVisible: false,
      snData: null,
    };
  },
  created() {
    this.$nextTick(() => {
      this.$refs.ording.queryHangList();
    });
  },
  mounted() {
    ipc.on(specialIpcRoute.window1ToWindow2, (event, arg) => {
      this.$refs.ording.queryHangList();
    });
  },
  methods: {
    closeSuited(row) {
      this.$confirm("是否确认关闭", "提示")
        .then(() => {
          visitorCancelApplay({
            appId: row.id,
          })
            .then((data) => {
              this.queryInvoices();
              this.$message({
                type: "success",
                message: "操作成功",
                duration: 2000,
                showClose: true,
              });
            })
            .catch((error) => {
              this.$message({
                type: "error",
                message: error,
                duration: 2000,
                showClose: true,
              });
            });
        })
        .catch((err) => {});
    },
    outOfStockOrder() {
      this.invoicesItem = {
        id: this.ordingData.id,
        orderTypeId: this.ordingData.orderTypeCode || "1",
        orderNo: this.ordingData.orderNo,
      };
      this.outOfStockVisible = true;
    },
    async outOfStockOrderDatil(row) {
      let options = {
        type: "vue",
        content: "/customerOrder",
        windowName: "客订记录",
        winId: "customerOrder",
        params: row.guestBookingOrderNo,
      };
      ipc.invoke(ipcApiRoute.createWindow, options).then((r) => {});
      const newWcId = await ipc.invoke(ipcApiRoute.getWCid, options.windowName);
      if (newWcId) {
        ipc.sendTo(newWcId, specialIpcRoute.window1ToWindow2, options);
      }
    },
    cancelClose(e) {
      this.CancelOrderModal = false;
      if (e === "refresh") {
        this.queryInvoices();
      }
    },
    btnClick(index) {
      if (this.hasReviewing === "Y") {
        this.$message({
          showClose: true,
          message: "有审批正在进行，无法操作按钮",
          type: "error",
          duration: 2000,
        });
      } else {
        if (index === "3") {
          this.queryPrintData("N");
        }
        if (index === "4") {
          if (this.$amount.lessThanOrEquals(this.ordingData.totalPrice, 0)) {
            this.payLose();
          } else {
            this.againPay = {
              againPayId: this.ordingId,
              againinvoicesNo: this.invoicesNo,
              thirdCouponInfos: this.thirdCouponInfos || []
            };
            this.orderMoney = {
              totalPrice: this.ordingData.totalPrice,
              receivedAmount: this.ordingData.receivedAmount,
            };
            if (this.ordingData.receivedAmount) {
              this.orderMoney.receivablePrice = (
                this.ordingData.totalPrice - this.ordingData.receivedAmount
              ).toFixed(2);
            }
            this.payTypeDialog = true;
          }
        }
        if (index === "7") {
          this.CancelOrderModal = true;
        }
        if (index === "16") {
          this.editorGoods("16");
        }
      }
    },
    editorGoods() {
      const cashierItem = JSON.parse(this.$cache.local.get("cashierDeskItem"));
      this.$confirm(
        "是否确认修改订单，确认后本单将取消，将会把货品信息带到收银台",
        "提示"
      )
        .then(() => {
          takeOutPendingList({
            terminalId: cashierItem ? cashierItem.id : "",
            id: this.ordingId,
            isUpdate: "Y",
          })
            .then(async (data) => {
              this.cancelHandel();
              let options = {
                type: "vue",
                content: "/deptCollectMoney",
                windowName: "收银台",
                winId: "deptCollectMoney",
                params: {
                  winId: "deptCollectMoney",
                },
              };
              ipc.invoke(ipcApiRoute.createWindow, options).then((r) => {});
              const newWcId = await ipc.invoke(
                ipcApiRoute.getWCid,
                options.windowName
              );
              if (newWcId) {
                ipc.sendTo(newWcId, specialIpcRoute.window1ToWindow2, data);
              }
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: err,
                duration: 2000,
                showClose: true,
              });
            });
        })
        .catch(() => {});
    },
    showDetail(row) {
      row.orderId = this.ordingId;
      this.promotionObj = row;
      this.promotionModal = true;
    },
    cancelHandel() {
      const cashierItem = JSON.parse(this.$cache.local.get("cashierDeskItem"));
      cancelOrdingState({
        orderId: this.ordingId,
        terminalId: cashierItem ? cashierItem.id : "",
      })
        .then((data) => {
          this.queryInvoices();
        })
        .catch((err) => {});
    },
    payLose() {
      const cashierItem = JSON.parse(this.$cache.local.get("cashierDeskItem"));
      generatePayInfo({
        id: this.ordingData.id,
        isAlteration: "Y",
        proceedsInfoList: [
          { id: "1", proceedsPrice: this.ordingData.totalPrice },
        ],
        terminalId: cashierItem ? cashierItem.id : "",
      })
        .then((data) => {
          this.queryInvoices("", "Y");
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err,
            duration: 2000,
            showClose: true,
          });
        });
    },
    queryPrintData(type) {
      let printer = this.$cache.local.get("printerModel");
      let printCode = this.$cache.local.get("retailReceiptCode");
      let params = {
        sn: this.ordingData.orderNo,
      };
      if (type === "Y") {
        this.webViewUrlPreview = `${
          request.printPreviewRoot
        }?printerName=${printer}&templateCode=${printCode}&printCount=0&data=${encodeURI(
          JSON.stringify(params)
        )}`;
      } else {
        this.DoReceiptsModal = true;
        let printCount = this.$cache.local.get("retailReceiptCount");
        this.webViewUrl = `${
          request.printPreviewRoot
        }?printerName=${printer}&templateCode=${printCode}&printCount=0&data=${encodeURI(
          JSON.stringify(params)
        )}`;
        let eggRootUrl = `${request.printEggPreviewRoot}?printerName=${printer}&templateCode=${printCode}&`;
        const eggSrc =
          eggRootUrl +
          `printCount=${printCount}&data=${encodeURI(
            JSON.stringify(params)
          )}&sn=${params["sn"]}`;
        ipc
          .invoke(ipcApiRoute.print, { contentUrl: eggSrc, sn: params["sn"] })
          .then((r) => {});
      }
    },
    scrollChange(e) {
      if (!this.scrollFlag) {
        return (this.scrollFlag = true);
      }
      let arr = this.domArray;
      let idx;
      arr.forEach((ele) => {
        if (e.target.scrollTop > ele.top) {
          idx = ele.id + "";
        }
      });
      this.curTab = idx;
    },
    getCurTab(e) {
      this.scrollFlag = false;
      this.curTab = e.id;
      let scrollHight = document.querySelector("#goods-scroll");
      if (e.index === 0) {
        return (scrollHight.scrollTop = 0);
      }
      scrollHight.scrollTop = this.domArray[e.index]?.top;
    },
    getCurOrdingObj(e) {
      this.tabList = [];
      this.domArray = [];
      if (e && e.id && e.invoicesNo) {
        this.ordingId = e.id;
        this.invoicesNo = e.invoicesNo;
        this.showList = e.item;
        this.queryInvoices(e.first, e.item.hasReceiptInfo);
      } else {
        this.ordingId = "";
        this.invoicesNo = "";
        return;
      }
      let pushData = {
        hasSkuInfo: { name: "货品信息", id: "0" },
        hasInvoicesInfo: { name: "单据信息", id: "2" },
        hasPromotionInfo: { name: "促销信息", id: "3" },
        hasMemberInfo: { name: "会员信息", id: "4" },
        hasPaymentInfo: { name: "收款信息", id: "5" },
        hasReceiptInfo: { name: "小票预览", id: "6" },
        hasReviewInfo: { name: "审批信息", id: "7" },
        hasAftersalesInfo: { name: "售后信息", id: "8" },
        logisticsInfo: { name: "物流信息", id: "9" },
        hasBookingInfo: { name: "客订信息", id: "10" },
      };
      Object.keys(pushData).forEach((key) => {
        if (Object.keys(e.item).includes(key) && e.item[key] === "Y") {
          this.tabList.push(pushData[key]);
        }
      });
    },
    async queryInvoices(fir, isminp) {
      let res = await posOrderPayInfo({id: this.ordingId})
      this.newFetchInfo();
      this.handeBtnList = [];
      this.infoDetailLoading = true;
      queryInvoicesInfo({
        orderId: this.ordingId,
      })
        .then((data) => {
          let scrollHight = document.querySelector("#goods-scroll");
          scrollHight.scrollTop = 0;
          this.curTab = "0";
          this.hasReceiptInfo = data.hasReceiptInfo;
          if (data.storeInfo) {
            this.goodsTable = data.storeInfo;
            if (!fir) {
              GridManager.refreshGrid(this.gridOption.gridManagerName);
            }
          }
          if (data.orderInfo) {
            this.ordingData = data.orderInfo;
            this.totalPrice = this.ordingData.totalRealPrice;
            if (
              this.showList.state !== this.ordingData.state ||
              this.showList.realAmount !== this.ordingData.receivedAmount
            ) {
              this.$refs.ording.changeState(
                this.ordingData.state,
                this.ordingData.receivedAmount
              );
            }
          }
          if (data.memberInfo) {
            this.vipData = data.memberInfo;
          } else {
            this.vipData = null;
          }
          if (data.paymentInfo) {
            this.payList = data.paymentInfo;
            this.showList.hasPaymentInfo = "Y";
            let index = this.tabList.findIndex((item) => {
              return item.id === "5";
            });
            if (index === -1) {
              this.tabList.push({
                id: "5",
                name: "收款信息",
              });
            }
            if (isminp === "Y") {
              this.queryPrintData("Y");
              this.showList.hasReceiptInfo = "Y";
              let idx = this.tabList.findIndex((item) => {
                return item.id === "6";
              });
              if (idx === -1) {
                this.tabList.push({
                  id: "6",
                  name: "小票预览",
                });
              }
            }
          } else {
            this.payList = [];
            this.webViewUrl = null;
            this.webViewUrlPreview = null;
          }
          if (this.hasReceiptInfo === "N") {
            let index = this.tabList.findIndex((item) => {
              return item.name === "小票预览";
            });
            if (index !== -1) {
              this.tabList.splice(index, 1);
            }
          }
          if (data.promotionInfo) {
            this.promotionInfo = data.promotionInfo || [];
          } else {
            this.promotionInfo = [];
          }
          if(data.thirdCouponInfos && data.thirdCouponInfos[0]){
            this.thirdCouponInfos = data.thirdCouponInfos
          }else{
            this.thirdCouponInfos = []
          }
          if(data)
          if (data.reviewInfos && data.reviewInfos[0]) {
            this.reviewInfos = data.reviewInfos;
            this.showList.hasReviewInfo = "Y";
            let index = this.tabList.findIndex((item) => {
              return item.id === "7";
            });
            if (index === -1) {
              this.tabList.push({
                name: "审批信息",
                id: "7",
              });
            }
          }
          if (data.hasReviewing) {
            this.hasReviewing = data.hasReviewing;
          }

          if (data.logisticsInfo && data.logisticsInfo.length) {
            this.logisticsInfo = data.logisticsInfo;
            this.showList.logisticsInfo = "Y";
            let index = this.tabList.findIndex((item) => {
              return item.id === "9";
            });
            if (index === -1) {
              this.tabList.push({
                name: "物流信息",
                id: "9",
              });
            }
          } else {
            this.logisticsInfo = [];
          }
          this.$nextTick(() => {
            this.tabList.forEach((item, index) => {
              this.domArray.push({
                label: item.name,
                id: item.id,
              });
            });
            this.domArray.map((item) => {
              let dom = document.querySelector(`#tabcont${item.id}`);
              if (dom) {
                item.top = dom.offsetTop;
              }
            });
          });
          if (data.operationStates && data.operationStates.length) {
            this.handeBtnList = data.operationStates;
          }
          setTimeout(() => {
            this.infoDetailLoading = false;
          }, 500);
          for (let key in this.unfoldObj) {
            this.unfoldObj[key] = true;
          }
        })
        .catch((err) => {
          this.infoDetailLoading = false;
          this.$message({
            showClose: true,
            message: err,
            type: "error",
            duration: 2000,
          });
        });
    },
    showGoodsTable() {
      if (this.goodsTable.length) {
        this.initCount = this.goodsTable.reduce((prev, cur) => {
          return prev + Number(cur.number);
        }, 0);
        return new Promise((resolve, reject) => {
          const tableData = {
            data: this.goodsTable,
          };
          resolve(tableData);
        });
      }
    },
    showGoodsTables() {
      if (this.newGoodsInfos.length) {
        this.currentCount = this.newGoodsInfos.reduce((prev, cur) => {
          return prev + Number(cur.number);
        }, 0);
        return new Promise((resolve, reject) => {
          const tableData = {
            data: this.newGoodsInfos,
          };
          resolve(tableData);
        });
      }
    },
    closePayModule(refresh) {
      this.payTypeDialog = false;
      if (refresh) {
        this.queryInvoices("", "Y");
      }
    },
    unfoldHandel(nat) {
      this.unfoldObj[nat] = !this.unfoldObj[nat];
    },
    newFetchInfo() {
      this.btnAction = {};
      this.newGoodsInfos = [];
      this.aftersalesInfo = [];
      this.showList.hasAftersalesInfo = "N";
      if (
        this.showList.afterSalesType !== "0" ||
        this.showList.isDefectiveSalary === "Y"
      )
        return;
      queryInvoiceNewInfo({
        id: this.ordingId,
      })
        .then((res) => {
          this.crmAmount = res.crmAmount;
          this.otherAmount = res.otherAmount;
          this.btnAction = {
            isRetreat: res.isRetreat,
            isAllRetreat: res.isAllRetreat,
            isActivityChange: res.isActivityChange,
            isSizeOrColorChange: res.isSizeOrColorChange,
            marketingQuality: res.marketingQuality,
            isLackGoodsBooking: res.isLackGoodsBooking,
            customerOrderNo: res.customerOrderNo,
          };
          let index = this.tabList.findIndex((item) => {
            return item.id === "1";
          });
          if (res.storeInfo && res.storeInfo.length) {
            this.newGoodsInfos = res.storeInfo;
            if (index === -1) {
              this.tabList.splice(1, 0, { name: "最新货品信息", id: "1" });
            }
          } else {
            if (index !== -1) {
              this.tabList.splice(index, 1);
            }
          }
          GridManager.refreshGrid(this.gridOption1.gridManagerName);
          let afterIdx = this.tabList.findIndex((item) => {
            return item.id === "8";
          });
          if (
            res.queryOrderAfterSalesPojos &&
            res.queryOrderAfterSalesPojos.length
          ) {
            this.aftersalesInfo = res.queryOrderAfterSalesPojos;
            this.showList.hasAftersalesInfo = "Y";
            if (afterIdx === -1) {
              this.tabList.push({
                id: "8",
                name: "售后信息",
              });
            }
          } else {
            if (afterIdx !== -1) {
              this.tabList.splice(afterIdx, 1);
            }
          }

          if (res.queryBookingApplyPojos.length) {
            this.bookingApplyList = res.queryBookingApplyPojos;
            this.showList.hasBookingInfo = "Y";
            let indexTab = this.tabList.findIndex((item) => {
              return item.id === "10";
            });
            if (indexTab === -1) {
              this.tabList.push({
                name: "客订信息",
                id: "10",
              });
            }
          } else {
            if (indexTab !== -1) {
              this.tabList.splice(indexTab, 1);
            }
            // this.bookingApplyList = [];
          }
          this.domArray = [];
          this.$nextTick(() => {
            this.tabList.forEach((item, index) => {
              this.domArray.push({
                label: item.name,
                id: item.id,
              });
            });
            this.domArray.map((item) => {
              let dom = document.querySelector(`#tabcont${item.id}`);
              if (dom) {
                item.top = dom.offsetTop;
              }
            });
          });

        })
        .catch((error) => {
          GridManager.refreshGrid(this.gridOption1.gridManagerName);
        });
    },
    exchangeHandel(type) {
      if (type === "1") {
        this.invoicesItem = {
          id: this.ordingData.id,
          orderTypeId: this.ordingData.orderTypeCode || "1",
        };
        queryBookingApplyPojo({
          id: this.ordingId,
        })
          .then((data) => {
            this.bookingApplyList = data;
            let falg = false;
            this.bookingApplyList?.map((item) => {
              if (item.state == 2) {
                return this.$message({
                  showClose: true,
                  message:
                    "已有客订匹配成功并发货，请在客订操作收货后妥善处理库存。",
                  type: "error",
                  duration: 2000,
                });
              }
              if (item.state == 0 || item.state == 1) {
                falg = true;
                return this.$message({
                  showClose: true,
                  message: "请先关闭客订。",
                  type: "error",
                  duration: 2000,
                });
              }
            });
            if (falg) {
              return;
            }
            this.returnVisible = true;
          })
          .catch((err) => {});
      } else if (type === "4") {
        this.invoicesItem = {
          id: this.ordingData.id,
          crmAmount: this.crmAmount,
          otherAmount: this.otherAmount,
          orderTypeId: this.ordingData.orderTypeCode || "1",
          marketingQuality: this.btnAction.marketingQuality,
        };
        this.allReturnVisible = true;
      } else if (type === "return") {
        this.invoicesItem = {
          id: this.ordingData.id,
          isUpdate: "Y",
        };
        this.isReturnFunction(this.invoicesItem);
      } else if (type === "noReturn") {
        this.invoicesItem = {
          id: this.ordingData.id,
          isUpdate: "N",
        };
        this.isReturnFunction(this.invoicesItem);
      } else {
        this.invoicesItem = {
          type: type,
          id: this.ordingData.id,
          orderTypeId: this.ordingData.orderTypeCode || "1",
        };
        this.exchangeVisible = true;
      }
    },
    isReturnFunction(obj) {
      this.$confirm(
        `是否${obj.isUpdate == "Y" ? "确认退货" : "取消退货"}？`,
        "提示"
      )
        .then(() => {
          this.infoDetailLoading = true;
          confirmedReturnOrder({
            id: obj.id,
            isUpdate: obj.isUpdate,
          })
            .then((data) => {
              if (data) {
                this.$message({
                  type: "success",
                  message: obj.isUpdate == "Y" ? "退货成功！" : "取消成功！",
                  duration: 2000,
                  showClose: true,
                });
                this.infoDetailLoading = false;
                this.$refs.ording.queryHangList();
              }
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: err,
                duration: 2000,
                showClose: true,
              });
              this.infoDetailLoading = false;
            });
        })
        .catch(() => {});
    },
    sendExpressage(row) {
      this.snData = {
        invoicesId: this.ordingData ? this.ordingData.id : "",
        orderNo: this.ordingData ? this.ordingData.orderNo : "",
      };
      if (row) {
        this.snData = { ...this.snData, ...row };
      }
      this.sendVisible = true;
    },
    cancelLogistics(row) {
      this.$confirm("确认取消该快递单", "提示")
        .then((res) => {
          erpPackageRecallJdExpress({
            waybillCode: row.expressNo,
          })
            .then((res) => {
              this.queryInvoices();
              this.$message({
                type: "success",
                message: "快递单取消成功",
                duration: 2000,
                showClose: true,
              });
            })
            .catch((error) => {
              this.$message({
                type: "error",
                message: error,
                duration: 2000,
                showClose: true,
              });
            });
        })
        .catch((err) => {});
    },
    showTrack(row) {
      this.trackData = {
        senderName: row.senderName,
        senderMobile: row.senderPhone,
        senderAddress: row.senderAddress,
        logisticsCompany: row.logisticsName,
        waybillNo: row.expressNo,
        queryType: row.queryType,
        queryUrl: row.queryUrl,
        logisticsCompanyId: row.logisticsId,
      };
      this.showTrackDialog = true;
    },
    refreshHandel(type) {
      if (type === 1) {
        this.returnVisible = false;
      } else if (type === 2) {
        this.exchangeVisible = false;
      } else if (type === 3) {
        this.allReturnVisible = false;
      } else {
        this.outOfStockVisible = false;
      }
      this.$refs.ording.queryHangList();
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  display: inline-block;
  width: 100%;
  line-height: 20px;
}

.section-main {
  width: calc(100vw - 520px);
  height: calc(100vh - 80px);
  margin-top: 10px;
  position: relative;

  .bot-fixed {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    height: 60px;
    box-shadow: 0px -2px 16px 0px rgba(4, 51, 97, 0.1);
    border-radius: 2px;
    font-family: "font-Regular";
    z-index: 9;

    .tools-btn {
      border-radius: 4px;
      color: #fff;
      width: 110px;
      text-align: center;
      line-height: 40px;
      margin-right: 10px;
      cursor: pointer;
      background: #067cf2;
    }
  }
}

.invoice-detail {
  height: calc(100vh - 200px);
  overflow: auto;
  position: relative;
  background: #fff;
  padding: 0 20px 20px;

  .card-bottom {
    border-bottom: 1px solid #e1e1e1;
  }

  .invoice-card {
    margin-bottom: 20px;

    .goods-top {
      font-family: "font-Medium";
      color: #656d78;
      height: 50px;

      .title-label {
        margin-right: 10px;
        height: 20px;
        width: 4px;
        background: #067cf2;
        float: left;
        margin-top: 2px;
      }

      .title-text {
        color: #000;
        font-size: 16px;
      }

      .show-hidden {
        cursor: pointer;
        font-size: 14px;
        color: #333;
      }
    }

    .top-bottom {
      border-bottom: 1px solid #e1e1e1;
    }

    .invoice-data {
      color: #999;
      margin-top: 20px;
      font-size: 14px;

      div {
        width: calc(100% / 3);
        text-align: left;
        line-height: 30px;

        span {
          color: #000;
        }
      }

      .red-text {
        color: red;
        font-size: 18px;
        font-family: "font-Medium";
      }
    }

    .receipts-img {
      margin-left: 10px;
      margin-top: 15px;

      .webview {
        width: 100%;
        min-height: 600px;
      }
    }
  }
}
</style>
